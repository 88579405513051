import React, { Component } from "react";
import {
  Container,
  Grid,
} from '@material-ui/core';
const _ = require('lodash');

class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  
  render() {
    return (
      <div className="content">
        <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <h4>BALLOONADV'S CONTENT MANAGEMENT</h4>
              </Grid>

            </Grid>
          </Container>
          
      </div>
    );
  }
}

export default Dashboard;
