import React from 'react';
import { Route, Switch,BrowserRouter as Router, Redirect } from "react-router-dom";
import { FaHeart, FaBars } from 'react-icons/fa';
import reactLogo from '../assets/img/logo.png';
import {adminRoutes,managerRoutes,shopownerRoutes} from '../routes'
import {defaultUserRole} from '../helpers'
import config from '../config';
import { useEffect } from 'react';
import { userService } from '../services';

const getRoutes = routes => {
  return routes.map((prop, key) => {
    return (
      <Route
        path={prop.path}
        render={props => (
          <prop.component path={prop.path}
            {...props}
          />
        )}
        key={key}
      />
    );
  });
};
const Main = (props) => {
  useEffect(()=>{
    userService.currentProfile().then((data)=>{
      //console.log(data);
    });
  },[props]);

  let role = defaultUserRole();
  let routes = [];
  if(role === "admin"){
    routes = adminRoutes;
  }
  if(role === "manager"){
    routes = managerRoutes;
  }
  if(role === "shop_owner"){
    routes = shopownerRoutes;
  }
  let getBrandText = function (pathname){
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(
          routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Dashboard";
  }

  
  return (
    <main>
      <div className="btn-toggle" onClick={() =>props.handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <header>
        <h1 style={{paddingLeft:20}}>
        {getBrandText(props.location.pathname)}
        </h1>
        
      </header>
      <Switch>
        {getRoutes(routes)}
        <Redirect path={config.baseUrl+""} to={config.baseUrl+"dashboard"} />
      </Switch>

      <footer>
        <small>
        Copyright © 2022 Balloon Adventure Thailand. All Rights Reserved.
        </small>
      </footer>
    </main>
  );
};
export default Main;