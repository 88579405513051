import React from 'react';
import { Route,Switch } from "react-router-dom";
//import PageDetail from './Detail';
import PageList from './List';
class WebOrdersPage extends React.Component {
    render(){
        return (
            <div className="content">
                <Switch>
                    {/*}
                    <Route path={this.props.path+"/view/:id"} 
                        render={props => (
                            <PageDetail path={this.props.path}
                            {...props}
                            showNotification = {this.props.showNotification}
                            />
                        )}
                        />*/}
                    <Route path={this.props.path}
                        render={props => (
                            <PageList path={this.props.path}
                            {...props}
                            showNotification = {this.props.showNotification}
                            />
                        )} 
                    />
                </Switch>
            </div>
        )
    }
}
export default WebOrdersPage;