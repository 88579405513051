import React,{useState} from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import {
  Row,
  Col
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FaTachometerAlt,FaSitemap,FaUsers,FaStore,FaBoxes,FaUserCog,FaThLarge,FaMusic, FaImages,FaPhotoVideo, FaListAlt, FaNewspaper, FaShareAltSquare, FaHeart,FaSignOutAlt,FaTags,FaCode, } from 'react-icons/fa';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import sidebarBg from '../assets/img/bg1.jpg';
import {userService} from '../services'
import config from '../config';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {defaultUserRole,isAdmin,user_roles,isManager,isShopowner,isSupport} from '../helpers'
import {
  withStyles,makeStyles
} from '@material-ui/core/styles';
const _ = require('lodash');

const theme = createMuiTheme({
  palette: {
    type: "dark",
  }
});

const CssFormControl = withStyles({
  root: {
    '& label.Mui-focused': {
      color: "#adadad",
    },
    '& label': {
      color: "#adadad",
    },
    '& input': {
      color: "#adadad",
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: "#adadad",
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: "#adadad",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: "#adadad",
      },
      '&:hover fieldset': {
          borderColor: "#adadad",
      },
      '&.Mui-focused fieldset': {
          borderColor: "#adadad",
      },
    },
    '& .MuiFormLabel-root' : {
      color: "#fff"
      },
      '& .MuiSelect-root' : {
          color: "#adadad",
      }
  },
})(FormControl);

const RoleSelect = ({role,roles,handlerRoleChange}) =>{
  const [userRole,setUserRole] = useState(role);
  const userRoles = roles;
  let count = 0;
  if(_.indexOf(userRoles,"admin") > -1){
    count++;
  }
  if(_.indexOf(userRoles,"support") > -1){
    count++;
  }
  if(_.indexOf(userRoles,"manager") > -1){
    count++;
  }
  if(_.indexOf(userRoles,"shop_owner") > -1){
    count++;
  }
  //console.log(userRoles.length);
  if(count <= 1){
    return <></>;
  }
  return (
    <Row style={{margin:10}}>
      <Col>
        <CssFormControl style={{width:'100%'}} variant="outlined" >
          <InputLabel>USER ROLE</InputLabel>
          <Select value={userRole} onChange={(e,v)=>{
            setUserRole(e.target.value);
            if(handlerRoleChange){
              handlerRoleChange(e.target.value);
            }
          }} label="USER ROLE">
            {
              (isAdmin() && (
                <MenuItem value={'admin'}>ADMIN</MenuItem>
              ))
            }
            {
              (isSupport() && (
                <MenuItem value={'support'}>SUPPORT</MenuItem>
              ))
            }
            {
              (isManager() && (
                <MenuItem value={'manager'}>MANAGER</MenuItem>
              ))
            }
            {
              (isShopowner() && (
                <MenuItem value={'shop_owner'}>SHOP OWNER</MenuItem>
              ))
            }
            
            
          </Select>
        </CssFormControl>
      </Col>
    </Row>
  )
}

const RoleMenus = ({role}) =>{
  if(role === "admin"){
    return (<>
        <Menu  iconShape="circle">
          <MenuItem icon={<FaTachometerAlt />}>
            DASHBOARD
            <Link to={config.baseUrl} />
          </MenuItem>
          <MenuItem icon={<FaBoxes />}>
            BOOKING
            <Link to={config.baseUrl+'booking'} />
          </MenuItem>
        </Menu>
    </>);
  }
  return (<></>);
}

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar,handlerRoleChange }) => {
  const [userRole,setUserRole] = useState(defaultUserRole());
  const userRoles = user_roles();
  

  return (
    
    <ProSidebar
      image={sidebarBg}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          BALLOONADV - CMS
        </div>
        
      </SidebarHeader>

      <SidebarContent>
       
        <RoleSelect role={userRole} roles={userRoles} handlerRoleChange={(v)=>{
          setUserRole(v);
          localStorage.setItem("role",v);
          if(handlerRoleChange){
            handlerRoleChange(v);
          }
        }} />
        <RoleMenus role={userRole} />
        
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            onClick={(e)=>{
              userService.logout();
              window.location = config.baseUrl+"logout";
            }}
            className="sidebar-btn"
            rel="noopener noreferrer" style={{cursor:'pointer'}}
          >
            <FaSignOutAlt />
            <span> LOGOUT</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
    
  );
};

export default Aside;