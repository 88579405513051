var jwt = require('jsonwebtoken');
export function authHeader() {
    // return authorization header with basic auth credentials
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}
export function currentMemberId(){
    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.memberId) {
        return user.memberId;
    }else{
        return null;
    }
}
export function isLogin(){
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        try {
            var decoded = jwt.verify(user.token, 'fbgidxit,t');
            //console.log(decoded);
            var current_time = Date.now() / 1000;
            if ( decoded.exp < current_time) {
                return false;
            }else{
                return true;
            }
        } catch(err) {
            // err
            return false;
        }
        return false;
    } else {
        return false;
    }
}