import Dashboard from './views/dashboard/Page';
import Booking from './views/booking/Page';

import { FaTachometerAlt,FaBoxes, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart,FaSignOutAlt,FaBuffer } from 'react-icons/fa';

export const allRoutes = [
    {
        path : "/admin/dashboard",
        name:"Dashboard",
        icon: FaTachometerAlt,
        component : Dashboard
    },
];

export const adminRoutes = [
    {
        path : "/admin/dashboard",
        name:"DASHBOARD",
        icon: FaTachometerAlt,
        component : Dashboard
    },
    {
        path : "/admin/booking",
        name:"BOOKING",
        icon: FaTachometerAlt,
        component : Booking
    }
]

export const suportRoutes = [
    {
        path : "/admin/dashboard",
        name:"Dashboard",
        icon: FaTachometerAlt,
        component : Dashboard
    }
]

export const managerRoutes = [
    {
        path : "/admin/dashboard",
        name:"Dashboard",
        icon: FaTachometerAlt,
        component : Dashboard
    }
]

export const shopownerRoutes = [
    {
        path : "/admin/dashboard",
        name:"Dashboard",
        icon: FaTachometerAlt,
        component : Dashboard
    }
]

export default allRoutes;