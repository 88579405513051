import { authHeader } from "../helpers";
import config from "../config";
import { v4 as uuidv4 } from "uuid";
const _ = require("lodash");

export const userService = {
  login,
  logout,
  currentProfile,
  changepassword,
};
function changepassword(data) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...{ "Content-Type": "application/json" }, ...authHeader() },
    body: JSON.stringify(data),
  };
  return fetch(config.apiUrl + `/auth/changepassword}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function currentProfile() {
  const requestOptions = {
    method: "POST",
    headers: { ...{ "Content-Type": "application/json" }, ...authHeader() },
  };
  return fetch(config.apiUrl + `/auth/token`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username,
      password,
      udid: uuidv4(),
      push_token: "default-cms-web-token",
    }),
  };
  return fetch(config.apiUrl + `/auth/login`, requestOptions)
    .then((response) => {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        // console.log(data);
        if (!response.ok) {
          if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            return Promise.reject("Invalid username or password.");
          }
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        return data;
      });
    })
    .then((user) => {
      // login successful if there's a user in the response
      if (user) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        user.authdata = window.btoa(username + ":" + password);
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    });
}
function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location = config.baseUrl;
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
