const _ = require('lodash');
export function isMyAccount(id){
    let user = JSON.parse(localStorage.getItem('user'));
    if(_.isUndefined(user.memberId)){
        return false;
    }
    if(user.memberId === id){
        return true;
    }
    return false;
}
export function isAdmin(){
    let user = JSON.parse(localStorage.getItem('user'));
    if(_.isUndefined(user.roles)){
        return false;
    }
    if(_.indexOf(user.roles,"admin") > -1){
        return true;
    }
    return false;
}
export function isSupport(){
    let user = JSON.parse(localStorage.getItem('user'));
    if(_.isUndefined(user.roles)){
        return false;
    }
    if(_.indexOf(user.roles,"support") > -1){
        return true;
    }
    return false;
}
export function isManager(){
    let user = JSON.parse(localStorage.getItem('user'));
    if(_.isUndefined(user.roles)){
        return false;
    }
    if(_.indexOf(user.roles,"manager") > -1){
        return true;
    }
    return false;
}
export function isShopowner(){
    let user = JSON.parse(localStorage.getItem('user'));
    if(_.isUndefined(user.roles)){
        return false;
    }
    if(_.indexOf(user.roles,"shop_owner") > -1){
        return true;
    }
    return false;
}
export function isCustomer(){
    let user = JSON.parse(localStorage.getItem('user'));
    if(_.isUndefined(user.roles)){
        return false;
    }
    if(_.indexOf(user.roles,"customer") > -1){
        return true;
    }
    return false;
}
export function canUseCms(){
    return (isAdmin() || isSupport() || isManager() ||isShopowner())
}
export function user_roles(){
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.roles) {
        return user.roles;
    } else {
        return [];
    }
}
export function defaultUserRole(){
    let role = localStorage.getItem('role') || false;
    if(role && role == "admin" && isAdmin()){
        return "admin";
    }
    if(role && role == "support" && isSupport()){
        return "support";
    }
    if(role && role == "manager" && isManager()){
        return "manager";
    }
    if(role && role == "shop_owner" && isShopowner()){
        return "shop_owner";
    }
    if (isAdmin()) {
        return 'admin';
    }else if(isSupport()){
        return 'support';
    }else if(isManager()){
        return 'manager';
    }else if(isShopowner()){
        return 'shop_owner'
    }else{
        return 'customer';
    }
}
export function objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }