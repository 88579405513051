import React from "react";
import { Container as Grid, Row, Col } from "react-bootstrap";
import config from "../../config";
import MaterialTable from "material-table";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { authHeader, getTrackingStatus, isSupport,isAdmin } from "../../helpers";
import moment from "moment";
const _ = require("lodash");

class PageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lg_default: "th",
    };
    this.tableRef = React.createRef();
  }
  componentDidMount() {
    //console.log(isAdmin());
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <MaterialTable
                title="All BOOKING"
                tableRef={this.tableRef}
                columns={[
                  {
                    title: "INVOICE NO.",
                    field: "invoice",
                    render : (rawData) =>{
                      return  rawData.invoice.toUpperCase();
                    }
                  },
                  {
                    title: "FULLNAME",
                    field: "fullname"
                  },
                  {
                    title: "EMAIL",
                    field: "email"
                  },
                  {
                    title: "AMOUNT",
                    field: "total_price"
                  },
                  {
                    title: "STATUS",
                    field: "status",
                    lookup : {0:"Pending",1:"Complted",2:"Cancel"}
                  },
                  {
                    title: "CREATED TIME",
                    field: "createdtime",
                    render: (rowData) => {
                      return moment(rowData.createdtime).format(
                        "DD MMM YYYY hh:mm"
                      );
                    },
                  }
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    let url = config.apiUrl + "/orders/list?";
                    url += "&limit=" + query.pageSize;
                    url += "&offset=" + query.page * query.pageSize;
                    url += "&search=" + query.search;
                    fetch(url, {
                      method: "GET",
                      headers: authHeader(),
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        resolve({
                          data: result.data,
                          page: result.page,
                          totalCount: result.totalCount,
                        });
                      });
                  })
                }
                actions={[
                  {
                    icon: "visibility",
                    tooltip: "View",
                    onClick: (event, rowData) => {
                      window.open(config.pdfUrl+rowData.invoice+".pdf",'_blank');
                    },
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  search: true,
                  paging: true,
                  sorting: true,
                }}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default PageList;
