import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {userService} from '../services'
import logo from '../logo.png';
import { canUseCms} from '../helpers'
import { Redirect } from 'react-router-dom';
import config from '../config';

const _ = require('lodash');
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      padding : '50px',
      position: 'absolute',
      marginLeft:'auto',
      marginRight:'auto',
      left:'50%',
      top:'50%',
      transform: 'translateX(-50%) translateY(-50%)',
      '& > .MuiPaper-root': {
            padding : theme.spacing(5),
            margin: theme.spacing(1),
            width: theme.spacing(50),
            height: 'auto',

            '& > .MuiTextField-root':{
                marginTop:theme.spacing(2),
                width : "100%"
            },

            '& > .MuiButton-root' : {
                marginTop : theme.spacing(3),
                width : '100%'
            }
      },
    },
    logo : {
        textAlign :'center',
        '& > img':{
            width : '150px',
            height: 'auto'
        }
    }
}));

function PublicLayout(){
    const classes = useStyles();
    let [username,setUsername] = useState("");
    let [password,setPassword] = useState("");
    return (
        <div className="login-container">
            <div className={classes.root}>
                <Paper component="form" onSubmit={(e)=>{
                     e.preventDefault()
                     //console.log("test");
                     if(!_.isEmpty(username) && !_.isEmpty(password)){
                        userService.login(username,password).then((data)=>{
                            //console.log(data);
                            if(canUseCms()){
                                window.location = config.baseUrl+"dashboard";
                            }else{
                                confirmAlert({
                                    title: 'Error Message!',
                                    message: "Permision denie.",
                                    buttons: [
                                      {
                                        label: 'OK',
                                        onClick: () => {
                                            userService.logout();
                                            window.location.reload(true);
                                        }
                                      }
                                    ]
                                });
                            }
                        },(error)=>{
                            confirmAlert({
                                title: 'Error Message!',
                                message: error,
                                buttons: [
                                  {
                                    label: 'OK',
                                    onClick: () => {}
                                  }
                                ]
                            });
                        })
                     }else{
                        confirmAlert({
                            title: 'Error Message!',
                            message: 'Please check your username or password.',
                            buttons: [
                              {
                                label: 'OK',
                                onClick: () => {}
                              }
                            ]
                        });
                     }
                }}  variant="outlined">
                    <div className={classes.logo}><img src={logo} /></div>
                    <TextField  id="username-required" label="USERNAME" variant="outlined" defaultValue={username} onChange={(e)=>{
                        setUsername(e.target.value);
                    }} />
                    <TextField  id="password-required" type="password" label="PASSWORD" defaultValue={password} variant="outlined" onChange={(e)=>{
                        setPassword(e.target.value);
                    }} />
                    <Button variant="contained" type="submit" color="default" disableElevation>
                        LOGIN
                    </Button>
                </Paper>
            </div>
        </div>
    ) 
}
export default PublicLayout;