import { authHeader } from "../helpers";
import config from "../config";
import { userService } from "./user.service";
const _ = require("lodash");

export const defaultService = {
  save,
  getById,
  update,
  all,
  update2,
  setActive,
  count,
  updateOrdeItem,
  updateOrdeItem2,
};
function setActive(id, is_active, model) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...{ "Content-Type": "application/json" }, ...authHeader() },
    body: JSON.stringify({ is_active, id }),
  };
  return fetch(config.apiUrl + `/${model}/` + id, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function updateOrdeItem(id, orderItem, model) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...{ "Content-Type": "application/json" }, ...authHeader() },
    body: JSON.stringify({ orderItem, id }),
  };
  return fetch(config.apiUrl + `/${model}/` + id, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function updateOrdeItem2(data, model) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...{ "Content-Type": "application/json" }, ...authHeader() },
    body: JSON.stringify(data),
  };
  return fetch(config.apiUrl + `/${model}/` + data.id, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function all(model, filter) {
  const requestOptions = {
    method: "GET",
    headers: { ...{ "Content-Type": "application/json" }, ...authHeader() },
  };

  return fetch(
    config.apiUrl + "/" + model + "?filter=" + JSON.stringify(filter),
    requestOptions
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getById(id, model, filter) {
  const requestOptions = {
    method: "GET",
    headers: { ...{ "Content-Type": "application/json" }, ...authHeader() },
  };
  return fetch(
    config.apiUrl + `/${model}/` + id + "?filter=" + JSON.stringify(filter),
    requestOptions
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function update(data, model) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...{ "Content-Type": "application/json" }, ...authHeader() },
    body: JSON.stringify(data),
  };
  return fetch(config.apiUrl + `/${model}/` + data.id, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function update2(data, model) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...{ "Content-Type": "application/json" }, ...authHeader() },
    body: JSON.stringify(data),
  };
  return fetch(config.apiUrl + `/${model}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function save(data, model) {
  const requestOptions = {
    method: "POST",
    headers: { ...{ "Content-Type": "application/json" }, ...authHeader() },
    body: JSON.stringify(data),
  };
  return fetch(config.apiUrl + `/${model}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function count(model, filter) {
  const requestOptions = {
    method: "GET",
    headers: { ...{ "Content-Type": "application/json" }, ...authHeader() },
  };
  if (_.isUndefined(filter)) {
    filter = {};
  }
  return fetch(
    config.apiUrl + `/${model}/count` + "?filter=" + JSON.stringify(filter),
    requestOptions
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }
      const error = (data.error && data.error.message) || response.statusText;
      return Promise.reject(error);
    }
    if (data.status === false) {
      const error = data.message;
      return Promise.reject(error);
    }
    return data;
  });
}
