import React from 'react';
import {isLogin} from './helpers'
import PrivateLayout from './layouts/PrivateLayout'
import PublicLayout from './layouts/PublicLayout';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
function App(props) {
  return (
    <>
      {
        isLogin() && (
          <PrivateLayout {...props} />
        )
      }
      {
        !isLogin() && (
          <PublicLayout {...props} />
        )
      }
    </>
  );
}

export default App;
